import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const StyledCard = styled.div`
    text-align: left;
    display: flex;
    & > .image {
        background-size: cover;
        background-position: center;
        width: 50%;
        padding-bottom: 25%;
    }

    & > .description {
        width: 60%;
    }
`

const Description = styled.div`
    padding: 24px;
    & > h3 {
        margin: 0;
        font-size: 32px;
        font-family: 'Nunito Sans', sans-serif;
    }
    & > p {
        margin: 12px 0 0;
        color: #999;
        white-space: pre-line;
    }
    & > a {
        margin: 24px 0 0;
        text-decoration: none;
        display: block;
        color: ${({ theme }) => theme.color};
    }
`

const Icons = styled.div`
    display: flex;
    margin-top: 20px;
`
const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 55px;
    margin-right: 30px;

    & > .icon-div {
        
        display:flex;
        justify-content:center;
        align-items:center;
        overflow:hidden
        width: 50px;
        height: 40px;
        padding: 3px;
    }

    .icon {
        flex-shrink:0;
        -webkit-flex-shrink: 0;
        max-width:100%;
        max-height:100%;
    }

    & > p {
        font-size: 10px;
        text-align: center;
    }
`
const context = require.context("../images/icons", true, /^\.\/.*\.png$/)

export default function ProjectCard({ src, title, description, href, tags }) {

    return <><StyledCard className="project-card">
        <div className="image" style={{ backgroundImage: `url(${src})` }} />
        <Description className="description">
            <h3>{title}</h3>
            <p>{description}</p>
            <a href={href} target="_blank" rel="noopener noreferrer">View more <FontAwesomeIcon icon={faExternalLinkAlt} /></a>

        </Description>
    </StyledCard>
        <Icons>
            {tags.map(tag => {
                return <IconWrapper>
                    <div className="icon-div"><img className="icon" src={context(`./${tag.icon}.png`)} /></div>
                    <p>{tag.text}</p>
                </IconWrapper>
            })}
        </Icons>
    </>
}