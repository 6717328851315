import React from "react"
import styled from "styled-components"
import LogoSVG from '../assets/logo.svg'

const HeaderWrapper = styled.header`
  height: 78px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 5px 14px 1px rgba(0,0,0,0.14);
  background-color: #fff;
`

const Title = styled.h1`
  background-image: url(/logo.svg);
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -99999pt;
  background-size: 120px;
  height: 100%;
  position: absolute;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ContactButton = styled.a`
  border: none;
  background: linear-gradient(90deg, #E400C3 0%, #BD00D5 44%, #6500FF 100%);
  border-radius: 36px;
  padding: 12px 24px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: none;
`

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <Title>Luyten</Title>
    <ContactButton href="mailto:info@luyten.ai">Contact us</ContactButton>
  </HeaderWrapper>
)

export default Header
