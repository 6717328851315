import React from 'react'
import styled from 'styled-components'

const PaginationWrapper = styled.div`
    position: fixed;
    right: 12px;
    top: 50%;
`

const PaginationBullet = styled.div`
    width: 16px;
    height: 16px;
    background-color: #333;
    border-radius: 100%;
`

function Pagination({ pages }) {

    return <PaginationWrapper>
        {new Array(pages).map((p, i) => <PaginationBullet />)}
    </PaginationWrapper>
}

const FullPageSliderContent = styled.div``

export default function FullPageSlider({ children }) {

    return <FullPageSliderContent>
        <Pagination pages={children.count} />
        {children}
    </FullPageSliderContent>
}