import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px 0;
    margin: 0 auto 0;
    @media (min-width: 768px) {
        max-width: 768px;
    }
`

export default Section