import React from 'react'
import styled from 'styled-components'
import { faGithub, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledFooter = styled.footer`
    text-align: center;
    margin: 24px auto;

    & > a {
        text-decoration: none;
        color: #000;
    }
`

export default function Footer() {

    return <StyledFooter>© {new Date().getFullYear()} Luyten 👋 <a href="mailto:info@luyten.ai">info@luyten.ai</a> </StyledFooter>
}