import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/sections/Home"
import FullPageSlider from "../components/FullPageSlider"
import Header from "../components/Header"
import Footer from "../components/sections/Footer"
import Projects from "../components/sections/Projects"
import We from "../components/sections/We"
import Services from "../components/sections/Services"
import GetStarted from "../components/sections/GetStarted"
import Focus from "../components/sections/Focus"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />
    <FullPageSlider>
      <Home />
      <Focus />
      <Projects />
      <We />
      <Services />
      <GetStarted />
    </FullPageSlider>
    <Footer />
  </Layout>
)

export default IndexPage
