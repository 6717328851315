import React from 'react'
import styled from 'styled-components'
import { faGithub, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Section from '../Section'

const Content = styled.footer`
    text-align: center;
    margin: 24px auto;

    display: flex;

    & > a {
        text-decoration: none;
        color: #000;
    }
`
const Title = styled.h2`
    flex: 1;
    font-size: 36px;
    font-family: 'Nunito Sans', sans-serif;
    text-align: left;
`

const Steps = styled.ul`
    flex: 1;
    text-align: left;
    font-size: 36px;
    font-family: 'Nunito Sans', sans-serif;
    list-style: decimal;

    & > li {
        margin: 36px 0 0;
    }

    & > li:first-child {
        margin: 0;
    }

    & > li span {
       font-size: 24px; 
    }
`

export default function GetStarted() {

    return <Section>
        <Content>
            <Title>It's easy to get started</Title>
            <Steps>
                <li><span>Tell us about your project.</span></li>
                <li><span>Let’s figure what needs to be made</span></li>
                <li><span>We’ll get you a specialized team to make it</span></li>
                <li><span>We both enjoy happy customers</span></li>
            </Steps>
        </Content>
    </Section>
}