import React from 'react'
import Section from '../Section'
import styled from 'styled-components'

const services = [
    {
        title: 'Web Development',
        description: `"You never get a second chance to make a first impression."
        Your company's website is your client's first impression of your business. Let us help you dazzle them with a clean and appealing UI.`,
        icon: 'web',
    },
    {
        title: 'App Development',
        description: `Allow your customers to carry your business on their phones 24/7, with robusts and intuitives Apps.`,
        icon: 'app',
    },
    {
        title: 'Chatbot Development',
        description: `We use the latest technologies, and don't try to reinvent the wheel. We create chatbots on top of the shoulder of giants, beneficiating of techs from Microsoft, IBM, and more.`,
        icon: 'chatbot',
    },
    {
        title: 'Design',
        description: `We have experience with businesses used by thousands of users and can help you bring life to your brand.`,
        icon: 'design',
    },
    {
        title: 'Consultancy',
        description: `We are always on top of these ever-changing technologies. Be it Web, Mobile, Machine Learning or Chatbot Development, we know the ins and outs of every platform and can help you make the right decisions.`,
        icon: 'consultancy',
    }
]

const ServiceContainer = styled.div`
    margin: 54px 0 0;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: right center;
    padding: 60px 0;

    & > h3 {
        font-size: 36px;
        font-family: 'Nunito Sans', sans-serif;
    }

    &> p {
        margin: 24px 0 0;
        font-size: 18px;
        color: #626262;
        width: 50%;
    }
`

const context = require.context("../../images/services", true, /^\.\/.*\.svg$/)


const Service = ({ title, description, icon }) => {

    return <ServiceContainer icon={context(`./${icon}.svg`)}>
        <h3>{title}</h3>
        <p>{description}</p>
    </ServiceContainer>
}

const Content = styled.div`
`

const Title = styled.h2`
    font-size: 42px;
    font-family: 'Nunito Sans', sans-serif;
`

export default function Services() {

    return <Section>
        <Content>
            <Title>Our Services</Title>
            {services.map(s => <Service {...s} />)}
        </Content>
    </Section>
}