import React from 'react'
import styled from 'styled-components'
import Section from "../Section"

const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 54px;
    font-family: 'Nunito Sans', sans-serif;
`

export default function We() {

    return <Section>
        <Text>We are experts in web, app and chatbot  development, using our knowledge  we help our customers build  amazing experiences for their users.</Text>
    </Section>
}