import React from 'react'
import Section from '../Section'
import styled from 'styled-components'

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 42px;
    font-family: 'Nunito Sans', sans-serif;
`

export default function Focus() {

    return <Section>
        <Content>
        At Luyten we focus on having all parties involved during the development of your product. Success for your product means success for you, and also for us.
        </Content>
    </Section>
}