import React from 'react'
import Section from '../Section'
import LogoSVG from '../../assets/logo.svg'
import styled from 'styled-components'

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 54px;
    font-family: 'Nunito Sans', sans-serif;
`

export default function Home() {

    return <Section>
        <Content>
            Product-first development.
        </Content>
    </Section>
}