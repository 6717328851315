import React from 'react'
import Section from '../Section'
import styled from 'styled-components'
import ProjectCard from '../ProjectCard'

const projects = [
    {
        src: './bf.png',
        title: 'Botsfactory',
        description: `Botsfactory is a chatbot building platform. 
        \n1. Create & Deploy your chatbot to Facebook Messenger in record time. 
        \n2. Build complex flows and interaction easily`,
        href: 'https://botsfactory.io',
        tags: [{ icon: "node", text: 'Node.js' }, { icon: "graphQL", text: 'GraphQL' }, { icon: "react", text: 'React' },{ icon: "redux", text: 'Redux' }, { icon: "mySQL", text: 'MySQL' }, { icon: "chatbot", text: 'Chatbot Development' }, { icon: "dialogFlow", text: 'DialogFlow' }]
    },
    {
        src: './ch.png',
        title: 'Cosmic Hype',
        description: `Trending news website with Infinite Scroll.`,
        href: 'https://cosmichype.com',
        tags: [{ icon: "node", text: 'Node.js' }, { icon: "graphQL", text: 'GraphQL' }, { icon: "react", text: 'React' }, { icon: "mySQL", text: 'MySQL' }, { icon: "next", text: 'NextJS' }]
    },
    {
        src: './ff.png',
        title: 'Friends Feud',
        description: `Friends Feud is a Facebook Instant Game, where you compete against your friends.\n
        Contestants must choose the 4 most popular answers to a question, without using their 3 strikes.\n
        After 3 rounds, the user that scores the most points wins the game.`,
        href: 'https://fb.gg/play/friendsfeud',
        tags: [{ icon: "node", text: 'Node.js' }, { icon: "graphQL", text: 'GraphQL' }, { icon: "react", text: 'React' }, { icon: "mySQL", text: 'MySQL' }, { icon: "facebook", text: 'Instant Games' }, { icon: "gcloud", text: 'Google Cloud Functions' }]
    },
    // {
    //     src: './kc.png',
    //     title: 'Kava Chess',
    //     description: `Play chess with your friends without leaving your Messages conversation!`,
    //     href: 'https://apps.apple.com/it/app/kava-chess/id1143682673?l=en',
    //     tags: ['Swift', 'XCode', 'iMessage Apps']
    // },
]

const Cards = styled.div`
    margin: 120px auto 0;

    @media (min-width: 768px) {
     
    }

    & > .project-card {
        margin: 60px 0 0;
        @media (min-width: 768px) {
        }
    }
`
const Title = styled.h2`
    font-size: 54px;
    font-family: 'Nunito Sans', sans-serif;
`

const Content = styled.div``

const context = require.context("../../images/projects", true, /^\.\/.*\.png$/)

export default function Projects() {

    return <Section>
        <Content>
            <Title>Latest Projects delivered by our teams</Title>
            <Cards>
                {projects.map(p => <ProjectCard {...p} src={context(p.src)} />)}
            </Cards>
        </Content>
    </Section>

}